import { useState } from "react";
import { Modal, Radio, message } from "antd";
import Button from "../../../reusable/Button";
import Input from "../../../reusable/Input";
import addTrip from "../../../../pages/trip-management/assets/add-trip.png";
import { endTrip, walletDeduct } from "../../../../services/TripManagementServices";
import endTripSvg from "../../../../pages/trip-management/assets/endTrip.svg"
import { validateFields } from "../../../../pages/trip-management/validations/validate";
import DropDown from "../../../reusable/DropDown";
import RadioInput from "../../../reusable/RadioInput";



export default function ManualCompleteModal({
    isManualCompleteModal,
    setIsManualCompleteModal,
    selectedRow,
    tripStatus,
    getRides,
    rowsPerPage,
    page,
    isTripDetails
}) {

    const [endTripDetails, setEndTripDetails] = useState({
        rentalDuration: "",
        totalKmReading: "",
        noOfdays: "",
        totalTollFee: "",
        totalStatePermitFee: "",
        totalParkingFee: "",
        others: ""
    })

    const [durationData, setDurationData] = useState([
        { "duration": 2 },
        { "duration": 3 },
        { "duration": 4 },
        { "duration": 5 },
        { "duration": 6 },
        { "duration": 7 },
        { "duration": 8 },
        { "duration": 9 },
        { "duration": 10 },
        { "duration": 11 },
        { "duration": 12 },
        { "duration": 15 }])


    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const validateFields = (tripType) => {
        const newErrors = {};

        if (!endTripDetails.totalKmReading) {
            newErrors.totalKmReading = "Please enter total km reading";
        }
        if (tripType === 3 && !endTripDetails.rentalDuration) {
            newErrors.rentalDuration = "Please enter rental duration";
        }
        if (tripType === 2 && !endTripDetails.noOfdays) {
            newErrors.noOfdays = "Please enter no of days";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    async function handleEndTrip() {

        if (!validateFields(selectedRow?.tripType)) {
            return;
        }

        const endTripPayload = {
            "endFrom": 1, // For admin dashboard
            "totalKm": +endTripDetails.totalKmReading,
            "totalTollFees": +endTripDetails.totalTollFee || 0,
            "totalStatePermit": +endTripDetails.totalStatePermitFee || 0,
            "totalParkingFees": +endTripDetails.totalParkingFee || 0,
            "otherFees": +endTripDetails.others || 0,
            ...(selectedRow?.tripType === 3 && { rentalDuration: +endTripDetails.rentalDuration.duration || 0 }),
            ...(selectedRow?.tripType === 2 && { noOfdays: +endTripDetails.noOfdays || 0 })

        }

        setLoading(true)
        try {
            const response = await endTrip(selectedRow.id, endTripPayload);
            if (response) {
                const deductWalletPayload = {
                    id: +selectedRow.id,
                    ...(selectedRow?.tripType === 3 && { rentalDuration: +endTripDetails.rentalDuration.duration || 0 }),
                    endFrom: 1 // Indicates ending from dashboard
                }
                const deductWallet = await walletDeduct(deductWalletPayload);
                if (deductWallet) {
                    message.success('Trip completed sucessfully!');
                    if (!isTripDetails) {
                        getRides(tripStatus, rowsPerPage, page + 1) // Getting the rides after manual allot
                    }
                    setEndTripDetails({
                        rentalDuration: "",
                        totalKmReading: "",
                        totalTollFee: "",
                        totalStatePermitFee: "",
                        totalParkingFee: "",
                        others: ""
                    })
                    setErrors({})
                    setLoading(false)
                    setIsManualCompleteModal(false);
                }
            }
        }
        catch (err) {
            if(err.response.data.error === "Wallet already deducted") {
                setEndTripDetails({
                    rentalDuration: "",
                    totalKmReading: "",
                    totalTollFee: "",
                    totalStatePermitFee: "",
                    totalParkingFee: "",
                    others: ""
                })
                getRides(tripStatus, rowsPerPage, page + 1)
                setErrors({})
                setLoading(false)
                setIsManualCompleteModal(false);
                return;
            }
            setLoading(false)
            message.error(err.response.data.message || err.response.data.error || err.message);
        }
    }

    return (
        <Modal
            title={
                <>
                    <img src={addTrip} />
                </>
            }
            visible={isManualCompleteModal}
            width={"1000px"}
            onCancel={() => {
                setIsManualCompleteModal(false); setEndTripDetails({
                    rentalDuration: "",
                    totalKmReading: "",
                    totalTollFee: "",
                    totalStatePermitFee: "",
                    totalParkingFee: "",
                    others: "",
                });
                setErrors({
                    totalKmReading: "",
                    rentalDuration: "",
                    noOfdays: ""
                })
            }}
            // afterClose={handleSearchOnClear}
            footer={null}
        >
            <div className=" w-full  mt-6 mb-10  ">
                <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
                    Trip closing details
                </div>
                <div className=" w-full flex flex-col  gap-4 lg:gap-9  pt-10">
                    <div className="w-full flex flex-wrap justify-start lg:justify-end items-center  gap-4 pr-4  ">
                        {
                            selectedRow?.tripType === 3 && <DropDown
                                label={"Rental Duration (in Hours)"}
                                placeholder={"Select duration"}
                                displayValue={"duration"}
                                data={durationData}
                                value={endTripDetails.rentalDuration}
                                setValue={(val) => setEndTripDetails((prev) => ({ ...prev, rentalDuration: val }))}
                                mandate={true}
                                error={errors.rentalDuration}
                            />
                        }

                        <Input
                            type={"number"}
                            label={"Total KM Reading"}
                            placeholder={"Enter total KM reading"}
                            value={endTripDetails.totalKmReading}
                            onChange={(e) => setEndTripDetails(prev => ({ ...prev, totalKmReading: e.target.value }))}
                            mandate={true}
                            error={errors.totalKmReading}
                        />

                        {
                            selectedRow?.tripType === 2 && <Input
                                type={"number"}
                                label={"Number of Days"}
                                placeholder={"Enter No Of Days"}
                                value={endTripDetails.noOfdays}
                                onChange={(e) => setEndTripDetails(prev => ({ ...prev, noOfdays: e.target.value }))}
                                mandate={true}
                                error={errors.noOfdays}
                            />
                        }
                        <Input
                            type={"number"}
                            label={"Total Toll Fee"}
                            placeholder={"Enter total toll fee"}
                            value={endTripDetails.totalTollFee}
                            onChange={(e) => setEndTripDetails(prev => ({ ...prev, totalTollFee: e.target.value }))}
                        />
                        {
                            ((selectedRow?.tripType !== 3) && (selectedRow?.tripType !== 4)) && <Input
                                type={"number"}
                                label={"Total State Permit Fee"}
                                placeholder={"Enter total state permit fee"}
                                value={endTripDetails.totalStatePermitFee}
                                onChange={(e) => setEndTripDetails(prev => ({ ...prev, totalStatePermitFee: e.target.value }))}
                            />
                        }

                        <Input
                            type={"number"}
                            label={"Total Parking Fee"}
                            placeholder={"Enter total parking fee"}
                            value={endTripDetails.totalParkingFee}
                            onChange={(e) => setEndTripDetails(prev => ({ ...prev, totalParkingFee: e.target.value }))}
                        />
                        <Input
                            type={"number"}
                            label={"Others"}
                            placeholder={"Enter others"}
                            value={endTripDetails.others}
                            onChange={(e) => setEndTripDetails(prev => ({ ...prev, others: e.target.value }))}
                        />
                        {/* <RadioInput
                            value={paymentValue}
                            setValue={item => { console.log('item', item); setPaymentValue(item) }}
                            options={[
                                { label: "Paid", value: "paid" },
                                { label: "Not Paid", value: "notPaid" },
                            ]}
                            label={'Payment Status'}
                            radioType='default'
                            mandate
                        /> */}
                        <Input
                            invisible={true}
                        />

                        {
                            selectedRow?.tripType === 4 && (
                                <Input
                                    invisible={true}
                                />
                            )
                        }

                    </div>
                    <div className="w-full flex flex-wrap items-center justify-center gap-3">

                        <Button
                            icon={!loading && endTripSvg}
                            text={"End trip"}
                            width={"auto"}
                            bgColor={"#1849A9"}
                            textColor={"#fff"}
                            borderColor={"#1849A9"}
                            onClick={handleEndTrip}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
