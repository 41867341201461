import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import filter from "../../../assets/filter.svg";

export default function TicketSummary({ data }) {

    const sizing = {
        margin: { right: 1 },
        width: 300,
        height: 300,
        legend: { hidden: true },
    };

    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100)?.toFixed(0)}`;
    };

    return (
        <>

            <div className='shadow-lg p-4 w-fit h-fit hidden  lg:flex flex-auto gap-8 pr-6 border rounded-md relative'>
                <button
                    className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                    onClick={() => { }}
                >
                    <img src={filter} alt="filter" />
                </button>
                <div className='flex flex-col'>
                    <p className="text-lg font-semibold pb-2 w-fit">Ticket Summary</p>
                    <PieChart
                        series={[
                            {
                                data,
                                arcLabel: getArcLabel,
                                innerRadius: 43,
                                outerRadius: 110,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 14,
                            },
                        }}
                        {...sizing}
                    />
                </div>
                <div className="flex flex-col gap-3 justify-center items-start mx-auto min-w-[200px] font-semibold relative">
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
                        <p>Solved</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#FE9900]" />
                        <p>Pending</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
                        <p>Rejected</p>
                    </div>
                    <div className='absolute flex bottom-0 text-lg pb-5'>
                        Total Tickets:<span className='text-[#00ADB5] pl-2'>{TOTAL}</span>
                    </div>
                </div>
            </div>
            <div className='shadow-lg p-4 w-fit h-fit lg:hidden flex flex-auto gap-8 pr-6 border rounded-md relative'>
                <button
                    className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                    onClick={() => { }}
                >
                    <img src={filter} alt="filter" />
                </button>
                <div className='flex flex-col'>
                    <p className="text-lg font-semibold pb-2 w-fit">Ticket Summary</p>
                    <div className='  flex bottom-0 text-lg pb-5'>
                        Total Tickets:<span className='text-[#00ADB5] pl-2'>{TOTAL}</span>
                    </div>
                    <PieChart
                        series={[
                            {
                                data,
                                arcLabel: getArcLabel,
                                innerRadius: 43,
                                outerRadius: 110,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 14,
                            },
                        }}
                        {...sizing}
                    />
                    <div className="flex flex-col gap-3 justify-center items-start mx-auto   font-semibold relative">
                        <div className="flex gap-2 justify-center items-center">
                            <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
                            <p>Solved</p>
                        </div>
                        <div className="flex gap-2 justify-center items-center">
                            <div className="w-3 h-3 rounded-full bg-[#FE9900]" />
                            <p>Pending</p>
                        </div>
                        <div className="flex gap-2 justify-center items-center">
                            <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
                            <p>Rejected</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}