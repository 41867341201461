import { Card, Modal } from "antd";
import React, { useState } from "react";
import Upload from "../../../reusable/Upload";
import Input from "../../../reusable/Input";
import DateInput from "../../../reusable/DateInput";
import Button from "../../../reusable/Button";
import addTrip from "../../../../pages/trip-management/assets/add-trip.png";
import axios from "axios";
import DateUtils from "../../../../services/DateUtils";
import { useLocation } from "react-router-dom";

const DocumentDetailsComponent = ({
  setAddDriverModal3,
  addDriverModal3,
  rcCopyFrontFile,
  setRcCopyFrontFile,
  rcCopyFrontFileName,
  setRcCopyFrontFileName,
  setRcCopyFrontFileUrls,
  rcCopyFrontError,
  isEditable3,
  rcCopyBackFile,
  setRcCopyBackFile,
  rcCopyBackFileName,
  setRcCopyBackFileName,
  setRcCopyBackFileUrls,
  rcCopyBackError,
  driverVerifyStatus,
  rcCopyRemark,
  setLatestReason,
  setRcCopyRemark,
  rejectDriver,
  rejectFunction,
  pendingFunction,
  validRejectButton,
  acceptFunction,
  insuranceFile,
  setInsuranceFile,
  setInsuranceFileUrl,
  insuranceFileName,
  setInsuranceFileName,
  insuranceExpDate,
  setInsuranceExpDate,
  insuranceExpDateError,
  insuranceRemark,
  setInsuranceRemark,
  drivingLicenseFrontFile,
  setDrivingLicenseFrontFile,
  drivingLicenseFrontFileName,
  setDrivingLicenseFrontFileName,
  setDrivingLicenseFrontFileUrls,
  drivingLicenseFrontError,
  licenseBatchExp,
  setLicenseBatchExp,
  licenseBatchExpError,
  drivingLicenseRemark,
  setLicenseNumberRemark,
  drivingLicenseBackFile,
  setDrivingLicenseBackFile,
  drivingLicenseBackFileName,
  setDrivingLicenseBackFileName,
  setDrivingLicenseBackFileUrls,
  drivingLicenseBackError,
  drivingLicense,
  setDrivingLicense,
  drivingLicenseValidation,
  fcCopyFile,
  setFcCopyFileUrls,
  setFcCopyFile,
  fcCopyFileName,
  setFcCopyFileName,
  fcCopyError,
  fcExpiryDate,
  setFcExpiryDate,
  fcCopyRemark,
  setFcCopyRemark,
  PermitAndTaxFile,
  setPermitAndTaxFileUrl,
  setPermitAndTaxFile,
  PermitAndTaxFileName,
  setPermitAndTaxFileName,
  permitExpDate,
  setPermitExpDate,
  permitExpDateError,
  permitAndTaxRemark,
  setPermitAndTaxRemark,
  pollutionCertificateFile,
  setPollutionCertificateFile,
  setPollutionCertificateFileUrls,
  pollutionCertificateFileName,
  setPollutionCertificateFileName,
  pollutionCertificateError,
  pollutionVerificationExpDate,
  setPollutionVerificationExpDate,
  pollutionCertificateRemark,
  setPollutionCertificateRemark,
  policeCertificateFile,
  setPoliceCertificateFile,
  policeCertificateFileName,
  setPoliceCertificateFileName,
  setPoliceCertificateFileUrls,
  policeCertificateError,
  policeVerificationExpDate,
  setPoliceVerificationExpDate,
  policeCertificateRemark,
  setPoliceCertificateRemark,
  aadharCardFrontFile,
  setAadharCardFrontFile,
  aadharCardFrontFileName,
  setAadharCardFrontFileName,
  setAadharCardFrontFileUrls,
  aadharCardFrontError,
  aadharNumber,
  setAadharNumber,
  aadharNumberValidation,
  aadharCardRemark,
  setAadharCardRemark,
  aadharCardBackFile,
  setAadharCardBackFile,
  aadharCardBackFileName,
  setAadharCardBackFileName,
  setAadharCardBackFileUrls,
  aadharCardBackError,
  setDrivingLicenseRemark,
  setRcCopyFrontError,
  setRcCopyBackError,
  setInsuranceExpDateError,
  setLicenseBatchExpError,
  setDrivingLicenseFrontError,
  setDrivingLicenseBackError,
  setFcCopyError,
  setPermitExpDateError,
  setPollutionCertificateError,
  setPoliceCertificateError,
  setAadharNumberValidation,
  setAadharCardFrontError,
  setAadharCardBackError,
  setDrivingLicenseValidation,
  handleSubmit,
  documentStatus,
  setDocumentStatus,
  fetchDriverData,
  driverIdForFetch,

  vehicleFrontViewWithDriverError,
  vehicleFrontImageWithNumberPlateError,
  vehicleBackImageWithNumberPlateError,
  vehicleFrontViewWithDriverFileName,
  vehicleFrontImageWithNumberPlateFileName,
  vehicleBackImageWithNumberPlateFileName,
  vehicleFrontViewWithDriverFile,
  vehicleFrontImageWithNumberPlateFile,
  vehicleBackImageWithNumberPlateFile,
  setVehicleFrontViewWithDriverError,
  setVehicleFrontImageWithNumberPlateError,
  setVehicleBackImageWithNumberPlateError,
  setVehicleFrontViewWithDriverFileName,
  setVehicleFrontImageWithNumberPlateFileName,
  setVehicleBackImageWithNumberPlateFileName,
  setVehicleFrontViewWithDriverFile,
  setVehicleFrontImageWithNumberPlateFile,
  setVehicleBackImageWithNumberPlateFile,
  vehicleImageRemark,
  setVehicleImageRemark,
  vehicleFrontViewWithDriverUrl,
  vehicleFrontImageWithNumberPlateUrl,
  vehicleBackImageWithNumberPlateUrl,
  setVehicleFrontViewWithDriverUrl,
  setVehicleFrontImageWithNumberPlateUrl,
  setVehicleBackImageWithNumberPlateUrl,
}) => {
  const RejectIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const AcceptIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M17 1L6 12L1 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleReject = (verificationKey) => {
    showConfirmationModal("reject", verificationKey);
  };

  const handleAccept = (verificationKey, remarksKey) => {
    showConfirmationModal("accept", verificationKey, remarksKey);
  };

  const [editModal, setEditModal] = React.useState(false);
  const [editDrivingLicense, setEditDrivingLicense] = React.useState(false);
  const [editAadharCard, setEditAadharCard] = React.useState(false);
  const [editModalSection, setEditModalSection] = React.useState("");

  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [confirmationAction, setConfirmationAction] = React.useState("");
  const [confirmationVerificationKey, setConfirmationVerificationKey] =
    React.useState("");
  const [confirmationRemarksKey, setConfirmationRemarksKey] = React.useState("");

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [selectedModal, setSelectedModal] = React.useState("");
  const location = useLocation();

  const showConfirmationModal = (action, verificationKey, remarksKey) => {
    setConfirmationAction(action);
    setConfirmationVerificationKey(verificationKey);
    setConfirmationRemarksKey(remarksKey);
    setConfirmationModal(true);
  };

  const handleSubmitForExpired = async () => {
    const data = {
      driverVerify: 0,
      manualExpDoc: null,
      manualExpDate: null
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${driverIdForFetch}`,
        data, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const handleConfirmation = async () => {
    setDisableSubmit(false);
    if (confirmationAction === "reject") {
      setDocumentStatus({
        ...documentStatus,
        [confirmationVerificationKey]: 2,
      });
      if (location?.pathname?.includes("expired")) handleSubmitForExpired();
      handleSubmit();
      rejectFunction(confirmationVerificationKey);
    } else if (confirmationAction === "accept") {
      acceptFunction(confirmationVerificationKey, confirmationRemarksKey);
      setDocumentStatus({
        ...documentStatus,
        [confirmationVerificationKey]: 1,
      });
    }
    setConfirmationModal(false);
  };

  const openEditModal = (section) => {
    setEditModalSection(section);
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setEditModalSection("");
    setRcCopyFrontFileName("");
    setRcCopyBackFileName("");
    setDrivingLicenseFrontFileName("");
    setAadharCardFrontFileName("");
    setAadharCardBackFileName("");
    setDrivingLicenseBackFileName("");
    setInsuranceFileName("");
    setFcCopyFileName("");
    setPermitAndTaxFileName("");
    setPollutionCertificateFileName("");
    setPoliceCertificateFileName("");
    setAadharNumber("");
    setDrivingLicense("");

    setRcCopyBackError("");
    setRcCopyFrontError("");
    setRcCopyFrontError("");
    setRcCopyBackError("");
    setInsuranceExpDateError("");
    setLicenseBatchExpError("");
    setAadharCardFrontError("");
    setAadharCardBackError("");
    setDrivingLicenseFrontError("");
    setDrivingLicenseBackError("");
    setFcCopyError("");
    setPermitExpDateError("");
    setPollutionCertificateError("");
    setPoliceCertificateError("");

    // setRcCopyBackFile("");
    // setRcCopyFrontFile("");
    // setRcCopyFrontFileUrls("");

    // setInsuranceFile("");
    // setDrivingLicenseFrontFile("");
    // setDrivingLicenseBackFile("");
    // setFcCopyFile("");
    // setPermitAndTaxFile("");
    // setPollutionCertificateFile("");
    // setPoliceCertificateFile("");
    // setAadharCardFrontFile("");
    // setAadharCardBackFile("");
    // setAadharNumber("");
    // setLatestReason("");
    // setRcCopyRemark("");
    // setInsuranceRemark("");
    // setDrivingLicenseRemark("");
    // setFcCopyRemark("");
    // setPermitAndTaxRemark("");
    // setPollutionCertificateRemark("");
    // setPoliceCertificateRemark("");
    // setAadharCardRemark("");
    // setAadharNumberValidation("");
    // setDrivingLicenseValidation("");
  };

  const getDocumentStatus = (verificationKey) => {
    const status = documentStatus[verificationKey];
    switch (status) {
      case 0:
      case 3:
        return "Pending";
      case 1:
        return "Accepted";
      case 2:
        return "Rejected";
      default:
        return "";
    }
  };
  const EditModalContent = React.memo(() => {
    switch (editModalSection) {
      case "RC Copy":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
              <div className="w-[300px]">
                <Upload
                  label="RC Copy (Front)"
                  selectedFile={rcCopyFrontFile}
                  setSelectedFile={setRcCopyFrontFile}
                  inputValue={rcCopyFrontFileName}
                  updateComponent={true}
                  setInputValue={setRcCopyFrontFileName}
                  setRcCopyFrontFileUrls={setRcCopyFrontFileUrls}
                  isError={rcCopyFrontError !== ""}
                  errorMessage={rcCopyFrontError}
                />
              </div>
              <div className="w-[300px]">
                <Upload
                  label="RC Copy (Back)"
                  selectedFile={rcCopyBackFile}
                  setSelectedFile={setRcCopyBackFile}
                  inputValue={rcCopyBackFileName}
                  updateComponent={true}
                  setInputValue={setRcCopyBackFileName}
                  setRcCopyBackFileUrls={setRcCopyBackFileUrls}
                  isError={rcCopyBackError !== ""}
                  errorMessage={rcCopyBackError}
                />
              </div>
            </div>
          </>
        );
      case "Insurance":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
              <div className="w-[300px]">
                <Upload
                  label="Insurance Certificate"
                  selectedFile={insuranceFile}
                  updateComponent={true}
                  setInsuranceFileUrl={setInsuranceFileUrl}
                  setSelectedFile={setInsuranceFile}
                  inputValue={insuranceFileName}
                  setInputValue={setInsuranceFileName}
                />
              </div>
              <DateInput
                label="Insurance Expiry Date"
                placeholder="Select insurance exp"
                value={insuranceExpDate ? DateUtils.convertDate(insuranceExpDate) : null}
                setValue={setInsuranceExpDate}
                error={
                  insuranceExpDateError !== "" ? insuranceExpDateError : ""
                }
              />
            </div>
          </>
        );

      case "FC Copy":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="FC Copy"
                  selectedFile={fcCopyFile}
                  setFcCopyFileUrls={setFcCopyFileUrls}
                  setSelectedFile={setFcCopyFile}
                  inputValue={fcCopyFileName}
                  setInputValue={setFcCopyFileName}
                  updateComponent={true}
                  isError={fcCopyError !== ""}
                  errorMessage={fcCopyError}
                />
              </div>
              <DateInput
                label="FC Expiry Date"
                placeholder="Select FC exp"
                value={fcExpiryDate ? DateUtils.convertDate(fcExpiryDate) : null}
                setValue={setFcExpiryDate}
                error={
                  insuranceExpDateError !== "" ? insuranceExpDateError : ""
                }
              />
            </div>
          </>
        );
      case "Permit & Tax":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="Permit & Tax Certificate"
                  selectedFile={PermitAndTaxFile}
                  updateComponent={true}
                  setPermitAndTaxFileUrl={setPermitAndTaxFileUrl}
                  setSelectedFile={setPermitAndTaxFile}
                  inputValue={PermitAndTaxFileName}
                  setInputValue={setPermitAndTaxFileName}
                />
              </div>
              <DateInput
                label="Permit & Tax Expiry Date"
                placeholder="Select permit exp"
                value={permitExpDate ? DateUtils.convertDate(permitExpDate) : null}
                setValue={setPermitExpDate}
                error={permitExpDateError !== "" ? permitExpDateError : ""}
              />
            </div>
          </>
        );
      case "Pollution Certificate":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="Pollution Certificate"
                  updateComponent={true}
                  selectedFile={pollutionCertificateFile}
                  setSelectedFile={setPollutionCertificateFile}
                  setPollutionCertificateFileUrls={
                    setPollutionCertificateFileUrls
                  }
                  inputValue={pollutionCertificateFileName}
                  setInputValue={setPollutionCertificateFileName}
                />
              </div>
              <DateInput
                label="Pollution Certificate Expiry Date"
                placeholder="Select pollution exp"
                value={pollutionVerificationExpDate ? DateUtils.convertDate(pollutionVerificationExpDate) : null}
                setValue={setPollutionVerificationExpDate}
                error={pollutionCertificateError}
                errorMessage={pollutionCertificateError}
              />
            </div>
          </>
        );
      case "Police Certificate":
        return (
          <>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="Police Certificate"
                  selectedFile={policeCertificateFile}
                  setSelectedFile={setPoliceCertificateFile}
                  inputValue={policeCertificateFileName}
                  setInputValue={setPoliceCertificateFileName}
                  updateComponent={true}
                  setPoliceCertificateFileUrls={setPoliceCertificateFileUrls}
                />
              </div>
              <DateInput
                label="Police Verification Expiry Date"
                placeholder="Select police verification exp"
                value={policeVerificationExpDate ? DateUtils.convertDate(policeVerificationExpDate) : null}
                setValue={setPoliceVerificationExpDate}
                error={
                  policeCertificateError !== "" ? policeCertificateError : ""
                }
              />
            </div>
          </>
        );
      case "Vehicle Images":
        return (
          <>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-8 justify-between items-center gap-3 pt-3">
              <div className="w-[300px] mt-4">
                <Upload
                  label="Vehicle Front View with Driver"
                  updateComponent={true}
                  selectedFile={vehicleFrontViewWithDriverFile}
                  setSelectedFile={setVehicleFrontViewWithDriverFile}
                  setVehicleFrontViewWithDriverUrl={setVehicleFrontViewWithDriverUrl}
                  inputValue={vehicleFrontViewWithDriverFileName}
                  setInputValue={setVehicleFrontViewWithDriverFileName}
                  isError={vehicleFrontViewWithDriverError !== ""}
                  errorMessage={vehicleFrontViewWithDriverError}
                />
              </div>
              <div className="w-[300px]">
                <Upload
                  label="Vehicle Front Image with Number Plate"
                  updateComponent={true}
                  selectedFile={vehicleFrontImageWithNumberPlateFile}
                  setSelectedFile={setVehicleFrontImageWithNumberPlateFile}
                  setVehicleFrontImageWithNumberPlateUrl={setVehicleFrontImageWithNumberPlateUrl}
                  inputValue={vehicleFrontImageWithNumberPlateFileName}
                  setInputValue={setVehicleFrontImageWithNumberPlateFileName}
                  isError={vehicleFrontImageWithNumberPlateError !== ""}
                  errorMessage={vehicleFrontImageWithNumberPlateError}
                />
              </div>
              <div className="w-[300px]">
                <Upload
                  label="Vehicle Front Image with Number Plate"
                  updateComponent={true}
                  selectedFile={vehicleBackImageWithNumberPlateFile}
                  setSelectedFile={setVehicleBackImageWithNumberPlateFile}
                  setVehicleBackImageWithNumberPlateUrl={setVehicleBackImageWithNumberPlateUrl}
                  inputValue={vehicleBackImageWithNumberPlateFileName}
                  setInputValue={setVehicleBackImageWithNumberPlateFileName}
                  isError={vehicleBackImageWithNumberPlateError !== ""}
                  errorMessage={vehicleBackImageWithNumberPlateError}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  });

  const EditIcon = React.memo(() => (
    <div className="   hover:bg-gray-200  p-1    flex justify-center items-center  rounded-md cursor-pointer     ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 14 16"
        fill="none"
      >
        <path
          d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  ));
  const [rcRemarkValidation, setRcRemarkValidation] = React.useState(false);
  const [insuranceRemarkValidation, setInsuranceRemarkValidation] =
    React.useState(false);
  const [drivingLicenseRemarkValidation, setDrivingLicenseRemarkValidation] =
    React.useState(false);
  const [fcCopyRemarkValidation, setFcCopyRemarkValidation] =
    React.useState(false);
  const [permitAndTaxRemarkValidation, setPermitAndTaxRemarkValidation] =
    React.useState(false);
  const [
    pollutionCertificateRemarkValidation,
    setPollutionCertificateRemarkValidation,
  ] = React.useState(false);
  const [
    policeCertificateRemarkValidation,
    setPoliceCertificateRemarkValidation,
  ] = React.useState(false);
  const [aadharCardRemarkValidation, setAadharCardRemarkValidation] =
    React.useState(false);

  const submitClickForRejection = () => {
    handleSubmit();
    const value = Object.values(documentStatus).some((value) => value === 2);
    if (value) rejectDriver();
  };

  const resetRemarks = async (key) => {
    const data = {
      [key]: ""
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/driver/update/${driverIdForFetch}`,
        data, {
        headers: {
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        }
      }
      );
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };
  return (
    <div className="w-screen md:w-full overflow-y-auto px-1 flex justify-center items-center">
      <Card className="w-full mt-6 shadow-md">
        <div className="w-full flex flex-col gap-3 lg:gap-6 rounded-md">
          <div className="w-full flex justify-between items-center">
            <div className="w-full text-start text-lg mt-1 font-medium text-gray-800">
              Document Details
            </div>
            {driverVerifyStatus === 0 && (
              <Button
                text={"Submit"}
                width={"150px"}
                onClick={submitClickForRejection}
                bgColor={"#175CD3"}
                textColor={"#ffffff"}
                disabled={disableSubmit}
              />
            )}
          </div>
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("rcCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("rcCertificateVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("rcCertificateVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("RC Copy");
                setSelectedModal("rcCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>

          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="RC Copy (Front)"
                selectedFile={rcCopyFrontFile}
                setSelectedFile={setRcCopyFrontFile}
                inputValue={rcCopyFrontFileName}
                disabled={!isEditable3}
                updateComponent={true}
                setInputValue={setRcCopyFrontFileName}
                setRcCopyFrontFileUrls={setRcCopyFrontFileUrls}
              />
            </div>
            <div className="w-[300px]">
              <Upload
                label="RC Copy (Back)"
                disabled={!isEditable3}
                selectedFile={rcCopyBackFile}
                setSelectedFile={setRcCopyBackFile}
                updateComponent={true}
                inputValue={rcCopyBackFileName}
                setInputValue={setRcCopyBackFileName}
                setRcCopyBackFileUrls={setRcCopyBackFileUrls}
              />
            </div>

            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={rcCopyRemark}
                  disabled={
                    driverVerifyStatus === 1 || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setRcCopyRemark(e.target.value);
                  }}
                  error={rcRemarkValidation}
                  setValue={setRcCopyRemark}
                />
                {driverVerifyStatus === 0 && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (rcCopyRemark === "" || rcCopyRemark === null) {
                          setRcRemarkValidation("Remark is required");
                        } else {
                          handleReject("rcCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() => handleAccept("rcCertificateVerify", "rcRemarks")}
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Vehicle Images */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("vehicleImageVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("vehicleImageVerify") ===
                    "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("vehicleImageVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("Vehicle Images");
                setSelectedModal("vehicleImageVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>

          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="Vehicle Front View with Driver"
                updateComponent={true}
                selectedFile={vehicleFrontViewWithDriverFile}
                setSelectedFile={setVehicleFrontViewWithDriverFile}
                disabled={!isEditable3}
                setVehicleFrontViewWithDriverUrl={setVehicleFrontViewWithDriverUrl}
                inputValue={vehicleFrontViewWithDriverFileName}
                setInputValue={setVehicleFrontViewWithDriverFileName}
              />
            </div>
            <div className="w-[300px]">
              <Upload
                label="Vehicle Front Image with Number Plate"
                updateComponent={true}
                selectedFile={vehicleFrontImageWithNumberPlateFile}
                setSelectedFile={setVehicleFrontImageWithNumberPlateFile}
                disabled={!isEditable3}
                setVehicleFrontImageWithNumberPlateUrl={setVehicleFrontImageWithNumberPlateUrl}
                inputValue={vehicleFrontImageWithNumberPlateFileName}
                setInputValue={setVehicleFrontImageWithNumberPlateFileName}
                isError={vehicleFrontImageWithNumberPlateError !== ""}
                errorMessage={vehicleFrontImageWithNumberPlateError}
              />
            </div>
            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={vehicleImageRemark}
                  disabled={
                    driverVerifyStatus === 1 || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  setValue={setVehicleImageRemark}
                  error={policeCertificateRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setVehicleImageRemark(e.target.value);
                  }}
                />
                {driverVerifyStatus === 0 && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (
                          vehicleImageRemark === "" ||
                          vehicleImageRemark === null
                        ) {
                          setPoliceCertificateRemarkValidation(
                            "Remark is required"
                          );
                        } else {
                          handleReject("vehicleImageVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() =>
                        handleAccept("vehicleImageVerify", "vehicleImageRemarks")
                      }
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={vehicleImageRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setVehicleImageRemark(e.target.value);
                  }}
                  setValue={setVehicleImageRemark}
                />
              </div>
            )}
          </div>

          <div className="w-[300px]">
            <Upload
              label="Vehicle Front Image with Number Plate"
              updateComponent={true}
              selectedFile={vehicleBackImageWithNumberPlateFile}
              setSelectedFile={setVehicleBackImageWithNumberPlateFile}
              disabled={!isEditable3}
              setVehicleBackImageWithNumberPlateUrl={setVehicleBackImageWithNumberPlateUrl}
              inputValue={vehicleBackImageWithNumberPlateFileName}
              setInputValue={setVehicleBackImageWithNumberPlateFileName}
              isError={vehicleBackImageWithNumberPlateError !== ""}
              errorMessage={vehicleBackImageWithNumberPlateError}
            />
          </div>
          <hr className="w-full border-[#E5E5E5] my-2" />

          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("insuranceCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("insuranceCertificateVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("insuranceCertificateVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("Insurance");
                setSelectedModal("insuranceCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>
          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="Insurance Certificate"
                selectedFile={insuranceFile}
                updateComponent={true}
                disabled={!isEditable3}
                setInsuranceFileUrl={setInsuranceFileUrl}
                setSelectedFile={setInsuranceFile}
                inputValue={insuranceFileName}
                setInputValue={setInsuranceFileName}
              />
            </div>
            <DateInput
              label="Insurance Expiry Date"
              placeholder="Select insurance exp"
              disabled={!isEditable3}
              value={insuranceExpDate ? DateUtils.convertDate(insuranceExpDate) : null}
              setValue={setInsuranceExpDate}
              error={insuranceExpDateError !== "" ? insuranceExpDateError : ""}
            />
            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={insuranceRemark}
                  disabled={
                    driverVerifyStatus === 1 || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  setValue={setInsuranceRemark}
                  error={insuranceRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setInsuranceRemark(e.target.value);
                  }}
                />
                {driverVerifyStatus === 0 && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (
                          insuranceRemark === "" ||
                          insuranceRemark === null
                        ) {
                          setInsuranceRemarkValidation("Remark is required");
                        } else {
                          handleReject("insuranceCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() => handleAccept("insuranceCertificateVerify", "insuranceCertificateRemarks")}
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Driving License (Front) and (Back) */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("drivingLicenseVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("drivingLicenseVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("drivingLicenseVerify")}
            </div>
            <div onClick={() => setEditDrivingLicense(true)}>
              <EditIcon />
            </div>
          </div>
          {driverVerifyStatus === 0 ||
            driverVerifyStatus === 1 ||
            driverVerifyStatus === 2 ? (
            <>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Front)"
                    updateComponent={true}
                    selectedFile={drivingLicenseFrontFile}
                    setSelectedFile={setDrivingLicenseFrontFile}
                    inputValue={drivingLicenseFrontFileName}
                    setInputValue={setDrivingLicenseFrontFileName}
                    setDrivingLicenseFrontFileUrls={
                      setDrivingLicenseFrontFileUrls
                    }
                    disabled={!isEditable3}
                  />
                </div>
                <DateInput
                  label="License Batch Expiry date"
                  placeholder="Select license batch exp"
                  value={licenseBatchExp ? DateUtils.convertDate(licenseBatchExp) : null}
                  disabled={!isEditable3}
                  setValue={setLicenseBatchExp}
                />

                <div className="flex flex-wrap gap-x-3">
                  <Input
                    label="Remarks"
                    width={200}
                    placeholder="Enter your remark"
                    disabled={
                      driverVerifyStatus === 1 || driverVerifyStatus === 2
                        ? true
                        : false
                    }
                    value={drivingLicenseRemark}
                    setValue={setLicenseNumberRemark}
                    error={drivingLicenseRemarkValidation}
                    onChange={(e) => {
                      setLatestReason(e.target.value);
                      setDrivingLicenseRemark(e.target.value);
                    }}
                  />

                  {driverVerifyStatus === 0 && (
                    <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                      <button
                        className="bg-[#FF0000] h-10 rounded px-3"
                        onClick={() => {
                          if (
                            drivingLicenseRemark === "" ||
                            drivingLicenseRemark === null
                          ) {
                            setDrivingLicenseRemarkValidation(
                              "Remark is required"
                            );
                          } else {
                            handleReject("drivingLicenseVerify");
                          }
                        }}
                      >
                        <RejectIcon />
                      </button>
                      <button
                        className="bg-[#175CD3] h-10 rounded px-3"
                        onClick={() => handleAccept("drivingLicenseVerify", "drivingLicenseRemarks")}
                      >
                        <AcceptIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Back)"
                    selectedFile={drivingLicenseBackFile}
                    setSelectedFile={setDrivingLicenseBackFile}
                    inputValue={drivingLicenseBackFileName}
                    updateComponent={true}
                    setInputValue={setDrivingLicenseBackFileName}
                    setDrivingLicenseBackFileUrls={
                      setDrivingLicenseBackFileUrls
                    }
                    disabled={!isEditable3}
                  />
                </div>
                <Input
                  label="Driving License Number"
                  placeholder="Enter driving license number"
                  value={drivingLicense}
                  disabled={!isEditable3}
                  onChange={(e) => setDrivingLicense(e.target.value)}
                />
                <div
                  className={
                    driverVerifyStatus === 0
                      ? "invisible w-[300px]"
                      : " invisible w-[200px]"
                  }
                ></div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Front)"
                    updateComponent={true}
                    selectedFile={drivingLicenseFrontFile}
                    setSelectedFile={setDrivingLicenseFrontFile}
                    inputValue={drivingLicenseFrontFileName}
                    setInputValue={setDrivingLicenseFrontFileName}
                    setDrivingLicenseFrontFileUrls={
                      setDrivingLicenseFrontFileUrls
                    }
                    disabled={!isEditable3}
                  />
                </div>
                <div className="w-[300px]">
                  <Upload
                    label="Driving License (Back)"
                    selectedFile={drivingLicenseBackFile}
                    setSelectedFile={setDrivingLicenseBackFile}
                    inputValue={drivingLicenseBackFileName}
                    updateComponent={true}
                    setInputValue={setDrivingLicenseBackFileName}
                    setDrivingLicenseBackFileUrls={
                      setDrivingLicenseBackFileUrls
                    }
                    disabled={!isEditable3}
                  />
                </div>
                <div className="w-full lg:w-[200px]">
                  <DateInput
                    label="License Batch Expiry date"
                    placeholder="Select license batch exp"
                    value={licenseBatchExp ? DateUtils.convertDate(licenseBatchExp) : null}
                    disabled={!isEditable3}
                    setValue={setLicenseBatchExp}
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Input
                    label="Driving License Number"
                    placeholder="Enter driving license number"
                    value={drivingLicense}
                    disabled={!isEditable3}
                    error={
                      drivingLicenseValidation !== ""
                        ? drivingLicenseValidation
                        : ""
                    }
                    onChange={(e) => setDrivingLicense(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* FC Copy */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("fcCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("fcCertificateVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("fcCertificateVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("FC Copy");
                setSelectedModal("fcCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>
          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="FC Copy"
                selectedFile={fcCopyFile}
                setFcCopyFileUrls={setFcCopyFileUrls}
                setSelectedFile={setFcCopyFile}
                inputValue={fcCopyFileName}
                setInputValue={setFcCopyFileName}
                disabled={!isEditable3}
                updateComponent={true}
              />
            </div>
            <DateInput
              label="FC Expiry Date"
              placeholder="Select FC exp"
              value={fcExpiryDate ? DateUtils.convertDate(fcExpiryDate) : null}
              setValue={setFcExpiryDate}
              disabled={!isEditable3}
            />
            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={fcCopyRemark}
                  disabled={
                    getDocumentStatus("fcCertificateVerify") === "Accepted" || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  setValue={setFcCopyRemark}
                  error={fcCopyRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setFcCopyRemark(e.target.value);
                  }}
                />

                {(driverVerifyStatus === 0 || (driverVerifyStatus === 1 && getDocumentStatus("fcCertificateVerify") !== "Accepted")) && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (fcCopyRemark === "" || fcCopyRemark === null) {
                          setFcCopyRemarkValidation("Remark is required");
                        } else {
                          handleReject("fcCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() => handleAccept("fcCertificateVerify", "fcCopyRemarks")}
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Permit & Tax Certificate */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("permitCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("permitCertificateVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("permitCertificateVerify")}
            </div>

            <div
              onClick={() => {
                openEditModal("Permit & Tax");
                setSelectedModal("permitCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>
          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="Permit & Tax Certificate"
                selectedFile={PermitAndTaxFile}
                disabled={!isEditable3}
                updateComponent={true}
                setPermitAndTaxFileUrl={setPermitAndTaxFileUrl}
                setSelectedFile={setPermitAndTaxFile}
                inputValue={PermitAndTaxFileName}
                setInputValue={setPermitAndTaxFileName}
              />
            </div>
            <DateInput
              label="Permit & Tax Expiry Date"
              placeholder="Select permit exp"
              value={permitExpDate ? DateUtils.convertDate(permitExpDate) : null}
              setValue={setPermitExpDate}
              disabled={!isEditable3}
            />

            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={permitAndTaxRemark}
                  disabled={
                    getDocumentStatus("permitCertificateVerify") === "Accepted" || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  setValue={setPermitAndTaxRemark}
                  error={permitAndTaxRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setPermitAndTaxRemark(e.target.value);
                  }}
                />

                {(driverVerifyStatus === 0 || (driverVerifyStatus === 1 && getDocumentStatus("permitCertificateVerify") !== "Accepted")) && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (
                          permitAndTaxRemark === "" ||
                          permitAndTaxRemark === null
                        ) {
                          setPermitAndTaxRemarkValidation("Remark is required");
                        } else {
                          handleReject("permitCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() => handleAccept("permitCertificateVerify", "permitTaxCertificateRemarks")}
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Pollution Certificate */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("pollutionCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("pollutionCertificateVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("pollutionCertificateVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("Pollution Certificate");
                setSelectedModal("pollutionCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>

          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="Pollution Certificate"
                updateComponent={true}
                selectedFile={pollutionCertificateFile}
                setSelectedFile={setPollutionCertificateFile}
                disabled={!isEditable3}
                setPollutionCertificateFileUrls={
                  setPollutionCertificateFileUrls
                }
                inputValue={pollutionCertificateFileName}
                setInputValue={setPollutionCertificateFileName}
              />
            </div>

            <DateInput
              label="Pollution Certificate Expiry Date"
              placeholder="Select pollution exp"
              value={pollutionVerificationExpDate ? DateUtils.convertDate(pollutionVerificationExpDate) : null}
              disabled={!isEditable3}
              setValue={setPollutionVerificationExpDate}
            />

            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  disabled={
                    getDocumentStatus("pollutionCertificateVerify") === "Accepted" || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  value={pollutionCertificateRemark}
                  setValue={setPollutionCertificateRemark}
                  error={pollutionCertificateRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setPollutionCertificateRemark(e.target.value);
                  }}
                />
                {(driverVerifyStatus === 0 || (driverVerifyStatus === 1 && getDocumentStatus("pollutionCertificateVerify") !== "Accepted")) && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (
                          pollutionCertificateRemark === "" ||
                          pollutionCertificateRemark === null
                        ) {
                          setPollutionCertificateRemarkValidation(
                            "Remark is required"
                          );
                        } else {
                          handleReject("pollutionCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() => handleAccept("pollutionCertificateVerify", "pollutionCertificateRemarks")}
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Police Certificate */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("policeVerificationCertificateVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("policeVerificationCertificateVerify") ===
                    "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("policeVerificationCertificateVerify")}
            </div>
            <div
              onClick={() => {
                openEditModal("Police Certificate");
                setSelectedModal("policeVerificationCertificateVerify");
              }}
            >
              <EditIcon />
            </div>
          </div>

          <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
            <div className="w-[300px]">
              <Upload
                label="Police Certificate"
                selectedFile={policeCertificateFile}
                setSelectedFile={setPoliceCertificateFile}
                disabled={!isEditable3}
                inputValue={policeCertificateFileName}
                setInputValue={setPoliceCertificateFileName}
                updateComponent={true}
                setPoliceCertificateFileUrls={setPoliceCertificateFileUrls}
              />
            </div>
            <DateInput
              label="Police Verification Expiry Date"
              placeholder="Select police verification exp"
              value={policeVerificationExpDate ? DateUtils.convertDate(policeVerificationExpDate) : null}
              disabled={!isEditable3}
              setValue={setPoliceVerificationExpDate}
            />{" "}
            {driverVerifyStatus === 0 ||
              driverVerifyStatus === 1 ||
              driverVerifyStatus === 2 ? (
              <div className="flex flex-wrap gap-3">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={policeCertificateRemark}
                  disabled={
                    getDocumentStatus("policeVerificationCertificateVerify") === "Accepted" || driverVerifyStatus === 2
                      ? true
                      : false
                  }
                  setValue={setPoliceCertificateRemark}
                  error={policeCertificateRemarkValidation}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setPoliceCertificateRemark(e.target.value);
                  }}
                />
                {(driverVerifyStatus === 0 || (driverVerifyStatus === 1 && getDocumentStatus("policeVerificationCertificateVerify") !== "Accepted")) && (
                  <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                    <button
                      className="bg-[#FF0000] h-10 rounded px-3"
                      onClick={() => {
                        if (
                          policeCertificateRemark === "" ||
                          policeCertificateRemark === null
                        ) {
                          setPoliceCertificateRemarkValidation(
                            "Remark is required"
                          );
                        } else {
                          handleReject("policeVerificationCertificateVerify");
                        }
                      }}
                    >
                      <RejectIcon />
                    </button>
                    <button
                      className="bg-[#175CD3] h-10 rounded px-3"
                      onClick={() =>
                        handleAccept("policeVerificationCertificateVerify", "policeVerificationCertificateRemarks")
                      }
                    >
                      <AcceptIcon />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="opacity-0 hidden pointer-events-none lg:flex">
                <Input
                  label="Remarks"
                  width={200}
                  placeholder="Enter your remark"
                  value={setLicenseNumberRemark}
                  onChange={(e) => {
                    setLatestReason(e.target.value);
                    setLicenseNumberRemark(e.target.value);
                  }}
                  setValue={setLicenseNumberRemark}
                />
              </div>
            )}
          </div>

          <hr className="w-full border-[#E5E5E5] my-2" />

          {/* Aadhar Card (Front) and (Back) */}
          <div className="w-full flex justify-between items-center">
            <div
              className={`
        text-sm font-medium  
        rounded-full
        px-3  py-1
     
        ${getDocumentStatus("adhaarCardVerify") === "Pending"
                  ? "text-[#B54708] bg-[#FFFAEB] "
                  : getDocumentStatus("adhaarCardVerify") === "Accepted"
                    ? "text-[#027A48] bg-[#ECFDF3]"
                    : "text-[#B42318]  bg-[#FEF3F2]"
                }
        }
        `}
            >
              {getDocumentStatus("adhaarCardVerify")}
            </div>

            <div onClick={() => setEditAadharCard(true)}>
              <EditIcon />
            </div>
          </div>

          {driverVerifyStatus === 0 ||
            driverVerifyStatus === 1 ||
            driverVerifyStatus === 2 ? (
            <>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Front)"
                    disabled={!isEditable3}
                    selectedFile={aadharCardFrontFile}
                    setSelectedFile={setAadharCardFrontFile}
                    inputValue={aadharCardFrontFileName}
                    updateComponent={true}
                    setInputValue={setAadharCardFrontFileName}
                    setAadharCardFrontFileUrls={setAadharCardFrontFileUrls}
                  />
                </div>
                <Input
                  label="Aadhar Number"
                  placeholder="Enter aadhar number"
                  value={aadharNumber}
                  disabled={!isEditable3}
                  error={
                    aadharNumberValidation !== "" ? aadharNumberValidation : ""
                  }
                  onChange={(e) => setAadharNumber(e.target.value)}
                />
                <div className="flex flex-wrap gap-3">
                  <Input
                    label="Remarks"
                    width={200}
                    disabled={
                      driverVerifyStatus === 1 || driverVerifyStatus === 2
                        ? true
                        : false
                    }
                    placeholder="Enter your remark"
                    value={aadharCardRemark}
                    error={aadharCardRemarkValidation}
                    onChange={(e) => {
                      setLatestReason(e.target.value);
                      setAadharCardRemark(e.target.value);
                    }}
                  />
                  {driverVerifyStatus === 0 && (
                    <div className="md:w-12 md:px-12 text-white h-9 mb-3 md:mb-0 mt-6 gap-3 rounded-lg flex justify-center items-center">
                      <button
                        className="bg-[#FF0000] h-10 rounded px-3"
                        onClick={() => {
                          if (
                            aadharCardRemark === "" ||
                            aadharCardRemark === null
                          ) {
                            setAadharCardRemarkValidation("Remark is required");
                          } else {
                            handleReject("adhaarCardVerify");
                          }
                        }}
                      >
                        <RejectIcon />
                      </button>
                      <button
                        className="bg-[#175CD3] h-10 rounded px-3"
                        onClick={() => handleAccept("adhaarCardVerify", "aadharRemarks")}
                      >
                        <AcceptIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Back)"
                    selectedFile={aadharCardBackFile}
                    setSelectedFile={setAadharCardBackFile}
                    disabled={!isEditable3}
                    inputValue={aadharCardBackFileName}
                    setInputValue={setAadharCardBackFileName}
                    setAadharCardBackFileUrls={setAadharCardBackFileUrls}
                    updateComponent={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Front)"
                    disabled={!isEditable3}
                    selectedFile={aadharCardFrontFile}
                    setSelectedFile={setAadharCardFrontFile}
                    inputValue={aadharCardFrontFileName}
                    updateComponent={true}
                    setInputValue={setAadharCardFrontFileName}
                    setAadharCardFrontFileUrls={setAadharCardFrontFileUrls}
                  />
                </div>

                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Back)"
                    selectedFile={aadharCardBackFile}
                    setSelectedFile={setAadharCardBackFile}
                    disabled={!isEditable3}
                    inputValue={aadharCardBackFileName}
                    setInputValue={setAadharCardBackFileName}
                    setAadharCardBackFileUrls={setAadharCardBackFileUrls}
                    updateComponent={true}
                  />
                </div>

                <div className="w-full lg:w-[200px]">
                  <Input
                    label="Aadhar Number"
                    placeholder="Enter aadhar number"
                    value={aadharNumber}
                    disabled={!isEditable3}
                    error={
                      aadharNumberValidation !== ""
                        ? aadharNumberValidation
                        : ""
                    }
                    onChange={(e) => setAadharNumber(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={editModal}
        width={"800px"}
        onCancel={() => {
          closeEditModal();
          fetchDriverData();
        }}
        footer={null}
      >
        <div className="w-full mt-6 mb-6">
          <div className="w-full text-start text-lg mt-1 font-semibold text-gray-800">
            Update {editModalSection}
          </div>
          <div className="w-full px-1">
            <EditModalContent />
          </div>
          <div className="w-full flex gap-6 justify-center items-center pt-8">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                // Validate fields based on the edited section
                let isValid = true;

                switch (editModalSection) {
                  case "RC Copy":
                    if (rcCopyFrontFile || rcCopyBackFile) {
                      if (!rcCopyFrontFileName) {
                        setRcCopyFrontError("Please upload RC Copy (Front)");
                        isValid = false;
                      }
                      if (!rcCopyBackFileName) {
                        setRcCopyBackError("Please upload RC Copy (Back)");
                        isValid = false;
                      }
                    } else {
                      setRcCopyFrontError("");
                      setRcCopyBackError("");
                    }
                    break;
                  case "Insurance":
                    if (insuranceFile) {
                      if (!insuranceExpDate) {
                        setInsuranceExpDateError(
                          "Please select insurance expiry date"
                        );
                        isValid = false;
                      }
                    } else {
                      setInsuranceExpDateError("");
                    }
                    break;

                  case "FC Copy":
                    if (fcCopyFile) {
                      if (!fcExpiryDate) {
                        setInsuranceExpDateError(
                          "Please select FC expiry date"
                        );
                        isValid = false;
                      }
                    } else {
                      setFcCopyError("");
                    }
                    break;
                  case "Permit & Tax":
                    if (PermitAndTaxFile) {
                      if (!permitExpDate) {
                        setPermitExpDateError(
                          "Please select permit expiry date"
                        );
                        isValid = false;
                      }
                    } else {
                      setPermitExpDateError("");
                    }
                    break;
                  case "Pollution Certificate":
                    if (pollutionCertificateFile) {
                      if (!pollutionVerificationExpDate) {
                        setPollutionCertificateError(
                          "Please select pollution expiry date"
                        );
                        isValid = false;
                      }
                    } else {
                      setPollutionCertificateError("");
                    }
                    break;
                  case "Police Certificate":
                    if (policeCertificateFile) {
                      if (!policeVerificationExpDate) {
                        setPoliceCertificateError(
                          "Please select police verification expiry date"
                        );
                        isValid = false;
                      }
                    } else {
                      setPoliceCertificateError("");
                    }
                    break;

                  default:
                    break;
                }

                if (isValid) {
                  handleSubmit();
                  if (location?.pathname?.includes("expired")) handleSubmitForExpired();
                  pendingFunction(selectedModal);
                  setRcCopyFrontError("");
                  setRcCopyBackError("");
                  setInsuranceExpDateError("");
                  setLicenseBatchExpError("");

                  setFcCopyError("");
                  setPermitExpDateError("");
                  setPollutionCertificateError("");
                  setPoliceCertificateError("");
                  setAadharNumberValidation("");
                  setAadharCardFrontError("");
                  setAadharCardBackError("");

                  closeEditModal();
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={editDrivingLicense}
        width={"800px"}
        onCancel={() => {
          setEditDrivingLicense(false);
          closeEditModal();
          fetchDriverData();
        }}
        footer={null}
      >
        <div className="w-full mt-6 mb-10">
          <div className="w-full text-start text-lg mt-1 font-semibold text-gray-800">
            Update Driving License
          </div>
          <div className="w-full px-1">
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="Driving License (Front)"
                  updateComponent={true}
                  selectedFile={drivingLicenseFrontFile}
                  setSelectedFile={setDrivingLicenseFrontFile}
                  inputValue={drivingLicenseFrontFileName}
                  setInputValue={setDrivingLicenseFrontFileName}
                  setDrivingLicenseFrontFileUrls={
                    setDrivingLicenseFrontFileUrls
                  }
                  isError={drivingLicenseFrontError !== ""}
                  errorMessage={drivingLicenseFrontError}
                />
              </div>
              <DateInput
                label="License Batch Expiry date"
                placeholder="Select license batch exp"
                value={licenseBatchExp ? DateUtils.convertDate(licenseBatchExp) : null}
                setValue={setLicenseBatchExp}
                error={licenseBatchExpError !== "" ? licenseBatchExpError : ""}
              />
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
              <div className="w-[300px]">
                <Upload
                  label="Driving License (Back)"
                  selectedFile={drivingLicenseBackFile}
                  setSelectedFile={setDrivingLicenseBackFile}
                  inputValue={drivingLicenseBackFileName}
                  updateComponent={true}
                  setInputValue={setDrivingLicenseBackFileName}
                  setDrivingLicenseBackFileUrls={setDrivingLicenseBackFileUrls}
                  isError={drivingLicenseBackError !== ""}
                  errorMessage={drivingLicenseBackError}
                />
              </div>
              <Input
                label="Driving License Number"
                placeholder="Enter driving license number"
                value={drivingLicense}
                error={
                  drivingLicenseValidation !== ""
                    ? drivingLicenseValidation
                    : ""
                }
                onChange={(e) => setDrivingLicense(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full flex gap-6 justify-center items-center pt-8">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                let isValid = true;

                if (drivingLicenseFrontFile || drivingLicenseBackFile) {
                  if (!licenseBatchExp) {
                    setLicenseBatchExpError("Please select license batch exp");
                    isValid = false;
                  }
                  if (!drivingLicense) {
                    setDrivingLicenseValidation("Please enter driving license");
                    isValid = false;
                  }
                } else {
                  setDrivingLicenseFrontError("");
                  setDrivingLicenseBackError("");
                }

                if (isValid) {
                  handleSubmit();
                  if (location?.pathname?.includes("expired")) handleSubmitForExpired();
                  pendingFunction("drivingLicenseVerify");
                  setRcCopyFrontError("");
                  setRcCopyBackError("");
                  setInsuranceExpDateError("");
                  setLicenseBatchExpError("");

                  setFcCopyError("");
                  setPermitExpDateError("");
                  setPollutionCertificateError("");
                  setPoliceCertificateError("");
                  setAadharNumberValidation("");
                  setAadharCardFrontError("");
                  setAadharCardBackError("");

                  setEditDrivingLicense(false);
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <>
            <img src={addTrip} width={40} alt="" />
          </>
        }
        visible={editAadharCard}
        width={"800px"}
        onCancel={() => {
          setEditAadharCard(false);
          closeEditModal();
          fetchDriverData();
        }}
        footer={null}
      >
        <div className="w-full mt-6 mb-10">
          <div className="w-full text-start text-lg mt-1 font-semibold text-gray-800">
            Update Aadhar Card
          </div>
          <div className="w-full px-1">
            <>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Front)"
                    selectedFile={aadharCardFrontFile}
                    setSelectedFile={setAadharCardFrontFile}
                    inputValue={aadharCardFrontFileName}
                    updateComponent={true}
                    setInputValue={setAadharCardFrontFileName}
                    setAadharCardFrontFileUrls={setAadharCardFrontFileUrls}
                    isError={aadharCardFrontError !== ""}
                    errorMessage={aadharCardFrontError}
                  />
                </div>
                <Input
                  label="Aadhar Number"
                  placeholder="Enter aadhar number"
                  value={aadharNumber}
                  error={
                    aadharNumberValidation !== "" ? aadharNumberValidation : ""
                  }
                  onChange={(e) => setAadharNumber(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 pt-3">
                <div className="w-[300px]">
                  <Upload
                    label="Aadhar Card (Back)"
                    selectedFile={aadharCardBackFile}
                    setSelectedFile={setAadharCardBackFile}
                    inputValue={aadharCardBackFileName}
                    setInputValue={setAadharCardBackFileName}
                    setAadharCardBackFileUrls={setAadharCardBackFileUrls}
                    isError={aadharCardBackError !== ""}
                    updateComponent={true}
                    errorMessage={aadharCardBackError}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="w-full flex gap-6 justify-center items-center pt-8">
            <Button
              text={"Submit"}
              width={"170px"}
              onClick={() => {
                let isValid = true;
                if (aadharCardFrontFile || aadharCardBackFile) {
                  if (!aadharNumber) {
                    setAadharNumberValidation("Please enter Aadhar number");
                    isValid = false;
                  }
                  if (!aadharCardFrontFileName) {
                    setAadharCardFrontError(
                      "Please upload Aadhar Card (Front)"
                    );
                    isValid = false;
                  }
                  if (!aadharCardBackFileName) {
                    setAadharCardBackError("Please upload Aadhar Card (Back)");
                    isValid = false;
                  }
                } else {
                  setAadharCardFrontError("");
                  setAadharCardBackError("");
                }
                if (isValid) {
                  handleSubmit();
                  if (location?.pathname?.includes("expired")) handleSubmitForExpired();
                  pendingFunction("adhaarCardVerify");
                  // resetRemarks("aadharCardRemark");
                  setRcCopyFrontError("");
                  setRcCopyBackError("");
                  setInsuranceExpDateError("");
                  setLicenseBatchExpError("");

                  setFcCopyError("");
                  setPermitExpDateError("");
                  setPollutionCertificateError("");
                  setPoliceCertificateError("");
                  setAadharNumberValidation("");
                  setAadharCardFrontError("");
                  setAadharCardBackError("");

                  setEditAadharCard(false);
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <div className="text-lg font-bold">
            {confirmationAction === "reject"
              ? "Reject Document"
              : "Accept Document"}
          </div>
        }
        visible={confirmationModal}
        onCancel={() => {
          setConfirmationModal(false);
        }}
        footer={[
          <div className=" w-full flex justify-end pt-5 gap-4 items-center">
            <Button
              width={"100px"}
              bgColor={"#ff000000"}
              textColor={"#000000"}
              text="Cancel"
              onClick={() => setConfirmationModal(false)}
            />
            <Button
              width={"100px"}
              bgColor={"#175CD3"}
              text="Confirm"
              onClick={handleConfirmation}
            />
          </div>,
        ]}
      >
        <div className="text-gray-700">
          Are you sure you want to{" "}
          {confirmationAction === "reject" ? "reject" : "accept"} this document?
        </div>
      </Modal>
    </div>
  );
};
export default DocumentDetailsComponent;
