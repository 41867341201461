import { Card } from "antd";
import React from "react";
import Upload from "../../../reusable/Upload";
import Input from "../../../reusable/Input";
import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";


const AddressDetailsComponent = ({
  isEditable2,
  setIsEditable2,
  tempStreetAddress,
  setTempStreetAddress,
  tempStreetAddressValidation,
  tempHouseNumber,
  setTempHouseNumber,
  tempHouseNumberValidation,
  tempState,
  setTempState,
  tempStateValidation,
  states,
  tempDistrict,
  setTempDistrict,
  tempDistrictValidation,
  districts,
  tempPincode,
  setTempPincode,
  tempPincodeValidation,
  permStreetAddress,
  setPermStreetAddress,
  permStreetAddressValidation,
  permHouseNumber,
  setPermHouseNumber,
  permHouseNumberValidation,
  permState,
  setPermState,
  permStateValidation,
  permDistrict,
  setPermDistrict,
  permDistrictValidation,
  permPincode,
  setPermPincode,
  permPincodeValidation,
  fetchDriverData,
  handleSubmit,
  setAddDriverModal2,
  addDriverModal2,
  content
}) => {
  return (
    <>
      <div className=" w-full px-1 flex justify-center items-center ">
        <Card className=" w-full  mt-6  shadow-md  ">
          <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
            <div className=" w-full flex justify-between items-center">
              <div className=" w-full text-start text-lg mt-1 font-medium text-gray-800 ">
                Address Details
              </div>
              <div className="  w-full flex justify-end items-center">
                {!isEditable2 && content(setAddDriverModal2, addDriverModal2)}
              </div>
            </div>
            {/* <div className="font-medium text-base underline">
              <p>Temporary Address</p>
            </div> */}
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3  lg:gap-10">
              <Input
                label={"Street Address"}
                placeholder={"Enter street address"}
                value={tempStreetAddress}
                setValue={setTempStreetAddress}
                onChange={(e) => {
                  setTempStreetAddress(e.target.value);
                }}
                disabled={!isEditable2}
                error={tempStreetAddressValidation}
              />
              <Input
                label={"House Number"}
                placeholder={"Enter house number"}
                value={tempHouseNumber}
                setValue={setTempHouseNumber}
                disabled={!isEditable2}
                onChange={(e) => {
                  setTempHouseNumber(e.target.value);
                }}
                error={tempHouseNumberValidation}
              />
              <DropDown
                label={"State"}
                placeholder={"Select state"}
                displayValue={"stateName"}
                data={states}
                value={tempState}
                setValue={setTempState}
                disabled={!isEditable2}
                error={tempStateValidation}
              />
            </div>
            <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
              <DropDown
                label={"District"}
                placeholder={"Select district"}
                displayValue={"districtName"}
                data={districts}
                value={tempDistrict}
                setValue={setTempDistrict}
                disabled={!isEditable2}
                error={tempDistrictValidation}
              />
              <Input
                label={"Enter Pincode"}
                type={"number"}
                placeholder={"Enter pincode"}
                value={tempPincode}
                setValue={setTempPincode}
                onChange={(e) => {
                  setTempPincode(e.target.value);
                }}
                disabled={!isEditable2}
                error={tempPincodeValidation}
              />

              <div className="w-[300px]" />
            </div>
            {/* <div className="flex flex-col w-full">
              <p className="text-base font-medium underline">
                Permanent Address
              </p>
            </div>
            <>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center gap-3 lg:gap-10">
                <Input
                  label={"Street Address"}
                  placeholder={"Enter street address"}
                  value={permStreetAddress}
                  onChange={(e) => {
                    setPermStreetAddress(e.target.value);
                  }}
                  setValue={setPermStreetAddress}
                  disabled={!isEditable2}
                  error={permStreetAddressValidation}
                />
                <Input
                  label={"House Number"}
                  placeholder={"Enter house number"}
                  value={permHouseNumber}
                  onChange={(e) => {
                    setPermHouseNumber(e.target.value);
                  }}
                  setValue={setPermHouseNumber}
                  disabled={!isEditable2}
                  error={permHouseNumberValidation}
                />
                <DropDown
                  label={"State"}
                  placeholder={"Select state"}
                  displayValue={"stateName"}
                  data={states}
                  value={permState}
                  setValue={setPermState}
                  disabled={!isEditable2}
                  error={permStateValidation}
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-between items-center  gap-3  lg:gap-10 ">
                <DropDown
                  label={"District"}
                  placeholder={"Select district"}
                  displayValue={"districtName"}
                  data={districts}
                  value={permDistrict}
                  setValue={setPermDistrict}
                  disabled={!isEditable2}
                  error={permDistrictValidation}
                />
                <Input
                  label={"Enter Pincode"}
                  type={"number"}
                  placeholder={"Enter pincode"}
                  value={permPincode}
                  setValue={setPermPincode}
                  disabled={!isEditable2}
                  onChange={(e) => {
                    setPermPincode(e.target.value);
                  }}
                  error={permPincodeValidation}
                />

                <div className="w-[300px]" />
              </div>
            </> */}
          </div>
          {isEditable2 && (
            <div className=" w-full flex justify-center mt-2  items-center">
              <button
                onClick={() => {
                  setIsEditable2(false);
                  fetchDriverData();
                }}
                className="bg-[#F04438] text-white rounded-md px-6 py-2 mt-4 mr-4"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Validate all fields before moving to the next modal
                  let isValid = true;

                  if (isValid) {
                    handleSubmit(setIsEditable2);
                  }
                }}
                className="bg-[#175CD3] text-white rounded-md px-6 py-2 mt-4"
              >
                Save
              </button>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default AddressDetailsComponent;