import axios from 'axios';

export const getAllAdminData = async (userType, page, rowsPerPage) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/getUsers?userType=${userType}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response) {
            return response.data;
        }
    }
    catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const CreateAdmin = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/signup`, { ...data }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response) {
            return response.data;
        }
    }
    catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const UpdateAdmin = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/updateUser`, { ...data }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response) {
            return response.data;
        }
    }
    catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const DeleteAdmin = async (id) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/auth/deleteUser/${id}`, {}, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response) {
            return response.data;
        }
    }
    catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}