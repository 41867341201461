import axios from 'axios';



export const getRedeemManagementList = async (redeemStatus, page, rowsPerPage, searchQuery) => {
    let query = '';
    if (searchQuery) {
        query = `&searchQuery=${searchQuery}`;
    }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/getRedeem/?redeemStatus=${redeemStatus}${query}&currentPage=${page}&pageSize=${rowsPerPage}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const updateRedeemStatus = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/updateRedeemStatus`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const getRedeemCount = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/getRedeemCount`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const updateCompleteRedeemStatus = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/updateCompleteRedeemStatus`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const updateRejectRedeemStatus = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/updateRejectRedeemStatus`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    }
    catch (error) {
        throw error;
    }
}

export const getRedeemOne = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/getRedeemOne/?id=${id}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    }
    catch (error) {
        throw error;
    }
}

export const initiateRedemption = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer/createRedeemRequest`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    }
    catch (error) {
        throw error;
    }
}