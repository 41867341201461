import { LineChart } from '@mui/x-charts/LineChart';
import filter from "../../../assets/filter.svg";

export default function AttendenceSummary({ presentData, absentData }) {

    const xLabels = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];

    return (
        <>
        <div className='shadow-lg p-4 w-fit  hidden lg:flex flex-col border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Attendance Summary</p>
            <LineChart
                width={380}
                height={300}
                series={[
                    { data: presentData, label: 'Present', color: '#3A97DE' },
                    { data: absentData, label: 'Absent', color: '#FF6587' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            <div className="flex gap-10 justify-center items-center font-semibold">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#3A97DE]" />
                    <p>Present</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#FF6587]" />
                    <p>Absent</p>
                </div>
            </div>
        </div>
        <div className='shadow-lg p-4 w-fit  flex lg:hidden flex-col border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Attendance Summary</p>
            <LineChart
                width={300}
                height={300}
                series={[
                    { data: presentData, label: 'Present', color: '#3A97DE' },
                    { data: absentData, label: 'Absent', color: '#FF6587' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            <div className="flex gap-10 justify-center items-center font-semibold">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#3A97DE]" />
                    <p>Present</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#FF6587]" />
                    <p>Absent</p>
                </div>
            </div>
        </div>
        </>
    );
}