import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";
import Input from "../../../reusable/Input";



export default function Filters({
    rideId,
    handleRideIdSearch,
    tripTypeData,
    tripTypeFilter,
    handleTripTypeFilter,
    vehicles,
    cabType,
    handleCabTypeFilter,
    fromDate,
    handleFromDateFilter,
    toDate,
    handleToDateFilter
}) {

    return (
        <>
            <Input
                label={"Trip ID"}
                placeholder={"Search trip ID"}
                value={rideId}
                onChange={(e) => handleRideIdSearch(e.target.value)}
            />
            <DropDown
                label={"Trip Type"}
                placeholder={"Select trip type"}
                displayValue={"tripName"}
                data={tripTypeData}
                value={tripTypeFilter}
                setValue={handleTripTypeFilter}
                selectAll
            />
            <DropDown
                label={"Vehicle Type"}
                placeholder={"Select vechicle type"}
                displayValue={"name"}
                data={vehicles}
                value={cabType}
                setValue={handleCabTypeFilter}
                selectAll
            />
            <DateInput
                label={"From date"}
                placeholder={"Select from date"}
                value={fromDate}
                setValue={handleFromDateFilter}
            />
            <DateInput
                label={"To date"}
                placeholder={"Select to date"}
                value={toDate}
                setValue={handleToDateFilter}
            />
        </>
    )
}