import axios from 'axios';

export const getTripSummary = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/home/getSummary`, data, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        debugger;
        if (error.response && error.response?.status === 401 || error.response?.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
};