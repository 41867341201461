import axios from "axios";


export const getEstimatedRideFare = async (rideDetails) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ride/rideEstimateFareBookingSite`, { ...rideDetails }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
            throw error;
        }
        return error.response && error.response.data;
    }
}


export const bookRide = async (rideDetails) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ride/bookRide`, { ...rideDetails }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const getAllRide = async (tripStatus, pageSize, currentPage, rideId, tripType, carType, fromDate, toDate) => {

    var queryParams = `?tripStatus=${tripStatus}&pageSize=${pageSize}&currentPage=${currentPage}`;

    if (rideId) {
        queryParams += `&rideId=${rideId}`;
    }

    if (tripType) {
        queryParams += `&tripType=${tripType}`;
    }

    if (carType) {
        queryParams += `&carType=${carType}`;
    }

    if (fromDate) {
        queryParams += `&fromDate=${fromDate}`;
    }

    if (toDate) {
        queryParams += `&toDate=${toDate}`;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ride/getRides${queryParams}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const updateRide = async (id, data) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/ride/updateRide?id=${id}`,
            { ...data },
            {
                headers: {
                    "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                    "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                    "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
                }
            }
        );
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const getRidesCounts = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ride/getRidesCounts`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}
export const getRidesIdDropDown = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ride/getRidesIdDropDown`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const getTripById = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ride/getRideById/?id=${id}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const updateTripData = async (id, data) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/ride/updateRide?id=${id}`, { ...data }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const endTrip = async (tripId, data) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/ride/endRide?id=${tripId}`, { ...data }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    }
    catch (error) {
        throw error;
    }
}

export const walletDeduct = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ride/walletDeductionAfterEndRide`, payload, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}