import { BarChart } from '@mui/x-charts/BarChart';
import filter from "../../../assets/filter.svg";

export default function TotalTripSummary({ data }) {

    const xLabels = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];

    return (
        <>
        <div className='shadow-lg p-4 pl-6 pt-6  hidden lg:flex flex-col flex-auto 2xl:max-w-[55%] h-fit border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Total Trip Summary</p>
            <BarChart
                width={700}
                height={300}
                series={[
                    { data: data, label: 'Total Trip', id: 'totalData', color: '#52D3D8' },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            {/* <div className="flex gap-4 justify-center items-center">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div> */}
        </div>
        <div className='shadow-lg px-4  pt-6  flex lg:hidden flex-col flex-auto 2xl:max-w-[55%] h-fit border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Total Trip Summary</p>
            <BarChart
                width={300}
                height={300}
                series={[
                    { data: data, label: 'Total Trip', id: 'totalData', color: '#52D3D8' },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            {/* <div className="flex gap-4 justify-center items-center">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div> */}
        </div>
        </>
    );
}
