import React, { useState } from 'react'
import Table from '../../reusable/Table/Table'
import TableHead from '../../reusable/Table/TableHead';
import TableCell from '../../reusable/Table/TableCell';
import ConfirmModal from '../../reusable/mdoal/ConfirmModal';
import SuccessModal from '../../reusable/mdoal/SuccessModal';
import { useNavigate } from 'react-router-dom';
import Input from "../../reusable/Input";
import DateUtils from "../../../services/DateUtils";
import MobileNumberInput from '../../reusable/MobileNumberInput';
import DateInput from '../../reusable/DateInput';
import Button from '../../reusable/Button';
import { Modal, message } from 'antd';
import { DeleteAdmin, UpdateAdmin } from '../../../services/AdminServices';

export default function AdminTable({ adminData, rowCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, activeKey, fetchNeeded, setFetchNeeded }) {
    const navigate = useNavigate();

    const [idForEdit, setIdForEdit] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [date, setDate] = useState(DateUtils.getCurrentDate());

    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [dateError, setDateError] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const adminTableColumns = [
        {
            id: "sno",
            name: <TableHead>S.No</TableHead>,
            cell: (row) => (
                <TableCell>
                    {row?.id}
                </TableCell>
            ),
        },
        {
            id: "name",
            name: <TableHead>Name</TableHead>,
            cell: (row) => <TableCell>{row?.name ? row?.name : "-"}</TableCell>,
        },
        {
            id: "mobileNumber",
            name: <TableHead>Mobile Number</TableHead>,
            cell: (row) => <TableCell>{row?.mobileNumber}</TableCell>,
        },
        // {
        //   id: "customerEmail",
        //   name: <TableHead>Email</TableHead>,
        //   cell: (row) => <TableCell>{row.email ? row.email : "-"}</TableCell>,
        // },
        {
            id: "email",
            name: <TableHead >Email</TableHead>,
            cell: (row) => <TableCell>{row.email ? row.email : "-"}</TableCell>,
        },

        {
            id: "actions",
            name: <TableHead>Actions</TableHead>,
            cell: (row) => (
                <TableCell>
                    <div class="py-3 text-center w-full flex justify-center items-center font-medium text-sm">
                        <button type="button" class="py-3 text-sm font-medium" onClick={() => handleEditModal(row)}>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                        </button>
                        <button type="button" class="py-3 ml-4 text-sm font-medium" onClick={() => { setSelectedRow(row); setConfirmModal(!confirmModal); }}>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"></path>
                            </svg>
                        </button>
                    </div>
                </TableCell>
            ),
        },

    ];

    const getUserType = () => {
        if (activeKey === 'super-admin') {
            return 'superAdmin';
        }
        else if (activeKey === 'admin') {
            return 'admin';
        }
        else if (activeKey === 'supervisor') {
            return 'supervisor';
        }
    }

    const handleEditModal = (row) => {
        setIdForEdit(row.id);
        setName(row.name);
        setEmail(row.email);
        setPassword(row.password);
        setMobileNumber(row.mobileNumber);
        setDate(row.date);
        setShowEditModal(!showEditModal);
    }

    const handleDelete = () => {
        setConfirmModal(!confirmModal);
        handleDeleteUser(selectedRow);
        setDeleteModal(!deleteModal);
    }

    const isValid = () => {
        let valid = true;
        if (name === "") {
            setNameError("Name is required");
            valid = false;
        } else {
            setNameError("");
        }
        if (email === "") {
            setEmailError("Email is required");
            valid = false;
        } else {
            setEmailError("");
        }
        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        } else {
            setPasswordError("");
        }
        if (mobileNumber === "") {
            setMobileNumberError("Mobile number is required");
            valid = false;
        } else {
            setMobileNumberError("");
        }
        if (date === "") {
            setDateError("Date is required");
            valid = false;
        } else {
            setDateError("");
        }
        return valid;
    };

    const handleEditUser = async () => {
        if (!isValid()) return;
        const data = {
            id: parseInt(idForEdit),
            name: name,
            email: email,
            password: password,
            mobileNumber: mobileNumber,
            date: date,
            createdBy: 1,
            userType: getUserType(),
        }
        const response = await UpdateAdmin(data);
        if (response.status) {
            message.success("User updated successfully");
            setShowEditModal(false);
            setName("");
            setEmail("");
            setPassword("");
            setMobileNumber("");
            setFetchNeeded(!fetchNeeded);
        } else {
            message.error(response.message);
        }
    };

    const handleDeleteUser = async (row) => {
        const response = await DeleteAdmin(row.id);
        if (response && response.status) {
            message.success("User deleted successfully");
            setFetchNeeded(!fetchNeeded);
            setDeleteModal(!deleteModal);
        } else {
            message.error(response.message);
        }
    };

    return (
        <div className="w-full removeScrollBar pb-5">
            <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
                {/* <CustomerFilters
                    customerId={filters.customerId}
                    customerTypeData={[{ id: 1, customerType: "Application" }, { id: 2, customerType: "Website" }, { id: 3, customerType: "Manual" }]}
                    customerType={filters.customerType}
                    fromDate={filters.fromDate}
                    toDate={filters.toDate}
                    setFilters={setFilters}
                /> */}

            </div>
            <Table
                columns={adminTableColumns}
                rows={adminData}
                rowCount={rowCount}
                rowsPerPage={rowsPerPage}
                // page={page}
                // handleChangePage={handleChangePage}
                // handleChangeRowsPerPage={handleChangeRowsPerPage}
                noNeedPagination={true}
            />
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                title={`Delete ${selectedRow.name}?`}
                description={"Do you want to delete this admin?"}
                button1={"No"}
                button2={"Yes"}
                onClickButton1={() => setConfirmModal(!confirmModal)}
                onClickButton2={() => {
                    // setDeleteModal(!deleteModal);
                    handleDelete();
                }}
            />
            <SuccessModal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                animation="delete"
                title={`${selectedRow.name} deleted successfully.`}
                onClose={() => navigate("/admin-management")}
            />
            <Modal
                title={
                    <div className="flex flex-col gap-2">
                        <p>Edit Super Admin</p>
                    </div>
                }
                visible={showEditModal}
                onCancel={() => {
                    setName("");
                    setEmail("");
                    setPassword("");
                    setMobileNumber("");
                    setShowEditModal(false);
                }}
                footer={null}
                width={700}
                centered
            >
                <div className=" w-full flex-col gap-10 mt-10  flex  justify-center items-center">
                    <div className=" gap-10 items-center flex-wrap flex w-full">
                        <Input
                            label="Name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={nameError}
                            type="text"
                            mandate
                        />
                        <Input
                            label="Email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={emailError}
                            type="text"
                            mandate
                        />
                    </div>
                    <div className="flex gap-10 items-center flex-wrap w-full">
                        <Input
                            label="Password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={passwordError}
                            type="text"
                            mandate
                        />
                        <MobileNumberInput
                            label="Mobile Number"
                            placeholder="Enter Mobile Number"
                            value={mobileNumber}
                            setValue={setMobileNumber}
                            error={mobileNumberError}
                            mandate
                        />
                    </div>
                    <div className="flex gap-10 items-center justify-start flex-wrap w-full">
                        <DateInput
                            label="Created Date"
                            placeholder="-"
                            value={date}
                            setValue={setDate}
                            error={dateError}
                            disabled
                        />
                    </div>
                    <div className="pb-2">
                        <Button
                            text="Save Changes"
                            width="130%"
                            borderColor="#1849A9"
                            type="button"
                            onClick={handleEditUser}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
