import { useContext, useEffect, useState } from 'react';
import AttendenceSummary from './charts/AttendenceSummary';
import EmployeeAttendence from './charts/EmployeeAttendence';
import EmployeeSummary from './charts/EmployeeSummary';
import TicketSummary from './charts/TicketSummary';
import TotalTripSummary from './charts/TotalTripSummary';
import TripSummary from './charts/TripSummary';
import TicketDetails from './charts/TicketDetails';
import { getTripSummary } from '../../services/HomeServices';
import DateUtils from '../../services/DateUtils';
import CommonContext from '../../context-storage/CommonServicesHandler';

export default function Home() {
    const { allVehicle, tripTypeData } = useContext(CommonContext);

    const [userName, setUserName] = useState("");
    const [tripData, setTripData] = useState([]);

    const [filtersTripSummary, setFiltersTripSummary] = useState({
        tripType: [],
        vehicleType: [],
    });
    const [filterDateTripSummary, setFilterDateTripSummary] = useState({
        firstDate: DateUtils.getTMinusDate(7),
        secondDate: DateUtils.getCurrentDate(),
    });

    useEffect(() => {
        setUserName("Shine");
    }, []);

    useEffect(() => {
        let bodyData = {
            tripType: filtersTripSummary.tripType.length ? filtersTripSummary.tripType : "",
            carType: filtersTripSummary.vehicleType.length ? filtersTripSummary.vehicleType : "",
            fromDate: filterDateTripSummary.firstDate,
            toDate: filterDateTripSummary.secondDate,
        };
        const fetchTripSummary = async () => {
            const data = await getTripSummary(bodyData);
            setTripData(data);
        }
        fetchTripSummary();
    }, [filtersTripSummary, filterDateTripSummary]);

    // TripSummary
    const TripData = [
        { label: 'New Bookings', value: tripData.newBooking, color: '#3266CC' },
        { label: 'Accepted', value: tripData.accepted, color: '#109618' },
        { label: 'Proceeded', value: tripData.proceeded, color: '#FF9900' },
        { label: 'App Allotted', value: tripData.appAlloted, color: '#109618' },
        { label: 'Manual Allotted', value: tripData.manualAlloted, color: '#990099' },
        { label: 'Completed', value: tripData.completed, color: '#008080' },
        { label: 'Cancelled', value: tripData.cancelled, color: '#DC3910' },
        { label: 'Faked', value: tripData.faked, color: '#8A2BE2' },
    ];
    // const TripData = [
    //     { label: 'New Bookings', value: tripData.newBooking, color: '#3266CC' },
    //     { label: 'Accepted', value: 2, color: '#109618' },
    //     { label: 'Proceeded', value: 6, color: '#FF9900' },
    //     { label: 'App Allotted', value: 3, color: '#109618' },
    //     { label: 'Manual Allotted', value: 6, color: '#990099' },
    //     { label: 'Completed', value: 7, color: '#008080' },
    //     { label: 'Cancelled', value: 1, color: '#DC3910' },
    //     { label: 'Faked', value: 4, color: '#8A2BE2' },
    // ];

    // TotalTripSummary
    const TotalTripData = [10, 15, 6, 9, 9, 13];

    // EmployeeAttendence
    const AttendenceData = [
        { label: 'Present', value: 400, color: '#3266CC' },
        { label: 'Absent', value: 300, color: '#DC3910' },
    ];

    // EmployeeSummary
    const TotalData = [50, 125, 100, 120, 50, 80, 160];
    const AllottedData = [20, 40, 60, 90, 70, 100, 50];

    // AttendenceSummary
    const PresentData = [70, 125, 70, 110, 90, 40];
    const AbsentData = [50, 110, 90, 90, 110, 80];

    // TicketSummary
    const TicketData = [
        { label: 'Solved', value: 120, color: '#3266CC' },
        { label: 'Pending', value: 90, color: '#FE9900' },
        { label: 'Rejected', value: 40, color: '#DC3910' },
    ];

    // TicketDetails
    const TicketCardsData = [
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        }, {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        }, {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
    ];

    return (
        <div className='flex flex-col gap-y-6 p-2  lg:p-8 overflow-y-auto h-[calc(100vh-50px)] md:h-[calc(100vh-10px)]'>
            <p className='text-3xl font-semibold pb-4 pt-2'>Welcome {userName} !</p>
            <div className="flex justify-between flex-wrap gap-5 w-full">
                <TripSummary
                    data={TripData}
                    allVehicle={allVehicle}
                    tripTypeData={tripTypeData}
                    setFilters={setFiltersTripSummary}
                    filters={filtersTripSummary}
                    filterDate={filterDateTripSummary}
                    setFilterDate={setFilterDateTripSummary}
                />
                <TotalTripSummary
                    data={TotalTripData}
                />
            </div>
            <div className="flex justify-between flex-wrap gap-x-2 gap-y-6 w-full">
                <EmployeeAttendence
                    data={AttendenceData}
                />
                <EmployeeSummary
                    totalData={TotalData}
                    allottedData={AllottedData}
                />
                <AttendenceSummary
                    presentData={PresentData}
                    absentData={AbsentData}
                />
            </div>
            <div className="flex justify-between flex-wrap gap-2 w-full">
                <TicketSummary
                    data={TicketData}
                />
                <TicketDetails
                    data={TicketCardsData}
                />
            </div>
        </div>
    );
}
