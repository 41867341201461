import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import filter from "../../../assets/filter.svg";

export default function EmployeeAttendence({ data }) {

    const sizing = {
        margin: { right: 1 },
        width: 250,
        height: 300,
        legend: { hidden: true },
    };
    const sizing2 = {
        margin: { right: 1 },
        width: 300,
        height: 300,
        legend: { hidden: true },
    };

    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100)?.toFixed(0)}`;
    };

    return (
        <>
            <div className='shadow-lg  hidden  p-4 w-fit  lg:flex  pr-6 border rounded-md relative'>
                <button
                    className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                    onClick={() => { }}
                >
                    <img src={filter} alt="filter" />
                </button>
                <div className='flex flex-col'>
                    <p className="text-lg font-semibold pb-2 w-fit">Employee Attendence</p>
                    <PieChart
                        series={[
                            {
                                data,
                                arcLabel: getArcLabel,
                                innerRadius: 43,
                                outerRadius: 110,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 14,
                            },
                        }}
                        {...sizing}
                    />
                </div>
                <div className="flex flex-col gap-3 justify-center items-center mx-auto pr-8 font-semibold">
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
                        <p>Present</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
                        <p>Absent</p>
                    </div>
                </div>
            </div>
            <div className='shadow-lg p-2 w-fit flex lg:hidden  pr-6 border rounded-md relative'>
                <button
                    className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                    onClick={() => { }}
                >
                    <img src={filter} alt="filter" />
                </button>
                <div className='flex flex-col'>
                    <p className="text-lg font-semibold pb-2 w-fit">Employee Attendence</p>
                    <PieChart
                        series={[
                            {
                                data,
                                arcLabel: getArcLabel,
                                innerRadius: 43,
                                outerRadius: 110,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 14,
                            },
                        }}
                        {...sizing2}
                    />
                    <div className="flex flex-col gap-3 justify-center items-center mx-auto pr-8 font-semibold">
                        <div className="flex gap-2 justify-center items-center">
                            <div className="w-3 h-3 rounded-full bg-[#3266CC]" />
                            <p>Present</p>
                        </div>
                        <div className="flex gap-2 justify-center items-center">
                            <div className="w-3 h-3 rounded-full bg-[#DC3910]" />
                            <p>Absent</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}