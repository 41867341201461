import { BarChart } from '@mui/x-charts/BarChart';
import filter from "../../../assets/filter.svg";

export default function EmployeeSummary({ totalData, allottedData }) {

    const xLabels = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun'
    ];

    return (
        <>
        <div className='shadow-lg p-4 w-fit  hidden lg:flex flex-col border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Employee Summary</p>
            <BarChart
                width={400}
                height={300}
                series={[
                    { data: totalData, label: 'Total Employees', id: 'totalData', color: '#52D3D8' },
                    { data: allottedData, label: 'Allotted Employees', id: 'allottedData', color: '#009EFF' },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            <div className="flex gap-10 justify-center items-start font-semibold">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div>
        </div>
        <div className='shadow-lg p-4 w-fit  flex lg:hidden flex-col border rounded-md relative'>
            <button
                className='absolute py-4 px-5 m-4 border border-border-gray rounded-lg top-0 right-0'
                onClick={() => { }}
            >
                <img src={filter} alt="filter" />
            </button>
            <p className="text-lg font-semibold w-fit">Employee Summary</p>
            <BarChart
                width={300}
                height={300}
                series={[
                    { data: totalData, label: 'Total Employees', id: 'totalData', color: '#52D3D8' },
                    { data: allottedData, label: 'Allotted Employees', id: 'allottedData', color: '#009EFF' },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                slotProps={{
                    legend: { hidden: true },
                }}
            />
            <div className="flex gap-10 justify-center items-start font-semibold">
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#52D3D8]" />
                    <p>Total</p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="w-3 h-3 rounded-full bg-[#009EFF]" />
                    <p>Alotted</p>
                </div>
            </div>
        </div>
        </>
    );
}
